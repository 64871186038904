import React from 'react';
import {ParagraphCitationComponentProps} from "@/components/paragraphs/types";
import {cn} from "@/lib/utils";

const ParagraphCitation = ({node, className, ...props} : ParagraphCitationComponentProps) => {
    return (
        <div className={
            cn(
                "flex flex-col bg-light sm:px-14 px-10 my-8 gap-2 items-center bg-quote",
                className
            )}
             {...props}
        >
            {node.field_body?.processed && (
                <div className="italic text-center" dangerouslySetInnerHTML={{__html: node.field_body.processed}}></div>
            )}
            {(node.field_auteur || node.field_complement) && (
                <div className="flex flex-col items-center gap-1">
                    {node.field_auteur && (<span className="text-lg text-foreground-dark">{node.field_auteur}</span>)}
                    {node.field_complement && (<span className="text-foreground-light">{node.field_complement}</span>)}
                </div>
            )}
        </div>
    );
};

export default ParagraphCitation;