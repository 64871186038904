import Image from "next/image"
import {DrupalNode} from "next-drupal"

import {absoluteUrl, formatDatetime} from "lib/utils"
import {Separator} from "@/components/ui/separator";
import Breadcrumbs from "@/components/breadcrumb";

interface NodeArticleProps {
    node: DrupalNode
}

export function NodeAnnonceLegale({node, ...props}: NodeArticleProps) {
  const breadcrumb = [
    {label: 'Annonces légales', href: '/annonces-legales' },
    {label: node.title}
  ];

    return (
        <article {...props}>
            <Breadcrumbs entries={breadcrumb} />

            <h1 className="mb-1 text-[40px] text-foreground-dark">{node.title}</h1>
            <Separator className='mb-4'/>
            <div className="mb-4 text-sm text-foreground-light uppercase">
                <span>{formatDatetime(node.created)}</span>
            </div>
            <div className="flex flex-col max-w-[564px] mx-auto gap-5">
                {node.field_image && (
                    <figure className="max-w-[768px]">
                        <Image
                            src={absoluteUrl(node.field_image.uri.url)}
                            width={768}
                            height={400}
                            alt={node.field_image.resourceIdObjMeta.alt}
                            priority
                            className="w-[564px] h-[400px] object-cover object-center"
                        />
                        {node.field_image.resourceIdObjMeta.title && (
                            <figcaption className="py-2 text-sm text-center text-gray-600">
                                {node.field_image.resourceIdObjMeta.title}
                            </figcaption>
                        )}
                    </figure>
                )}
                {node.body?.processed && (
                    <div
                        dangerouslySetInnerHTML={{__html: node.body?.processed}}
                    />
                )}
            </div>
        </article>
    )
}
