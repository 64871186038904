import Image from "next/image"
import {DrupalNode} from "next-drupal"
import {absoluteUrl, formatDate} from "lib/utils"
import {IconPlayerPlayFilled} from "@tabler/icons-react";
import {Button} from "@/components/ui/button";
import {AudioPlayerButton} from "@/components/audioPlayer";
import Link from "next/link";
import {Separator} from "@/components/ui/separator";

interface NodePodcastPromotedProps {
  podcasts: DrupalNode[]
}

export function NodePodcastPromoted({podcasts, ...props}: NodePodcastPromotedProps) {

  return (
    <div {...props}>

      <div className="grid lg:grid-cols-2 gap-4">

        {podcasts.map((podcast, index) => {
          return (
            <article key={index}>
              <div className="flex gap-4 mb-6">
                {
                  podcast.field_media_illustration && (
                    <Link href={podcast.path.alias} className="grow-0 shrink-0">
                      <figure className="relative">
                        <Image className='rounded-lg md:w-[140px] md:h-[140px] w-[70px] h-[70px] aspect-square object-cover object-center'
                               src={absoluteUrl(podcast.field_media_illustration.field_media_image.uri.url)}
                               width='140'
                               height='140'
                               alt={podcast.field_media_illustration.field_media_image.resourceIdObjMeta.alt ? podcast.field_media_illustration.field_media_image.resourceIdObjMeta.alt : ''}
                               priority
                        />

                        {podcast.field_fichier_video?.field_media_oembed_video && (
                          <div
                            className="bg-neutral-800 text-white p-1 md:text-xs text-[10px] leading-none text-center text-gray-600 absolute md:top-2 max-md:bottom-1 left-2 max-md:left-1 rounded-sm opacity-80">
                            VIDEO
                          </div>
                        )}
                      </figure>
                    </Link>
                  )
                }

                <div className="flex-auto flex flex-col justify-between">
                  <div>
                    <Link href={podcast.path.alias}>
                      <h1 className="mb-1 text-[20px] text-foreground-dark line-clamp-1">{podcast.title}</h1>
                    </Link>

                    {podcast.body?.summary && (
                      <span className="text-[14px] line-clamp-2">{podcast.body?.summary}</span>
                    )}
                  </div>

                  {(podcast.field_fichier && podcast.field_fichier.field_google_storage_link) && (
                    <div className='hidden md:flex gap-2 items-center w-full'>
                      <Button asChild size="icon" iconLeft={<IconPlayerPlayFilled size='20'/>}>
                        <AudioPlayerButton media={{
                          'src': `${podcast.field_fichier.field_google_storage_link}`,
                          'metadatas': {
                            'illustration': `${absoluteUrl(podcast.field_media_illustration.field_media_image.uri.url)}`,
                            'title': `${podcast.field_emission?.title ? podcast.field_emission.title : ''}`,
                            'episode': `${podcast.title}`,
                            'presenter': `${podcast.field_animateurs[0].title}`,
                          }
                        }}>
                        </AudioPlayerButton>
                      </Button>

                      <span
                        className="text-[14px] text-foreground-light uppercase">{formatDate(podcast.created)}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="block md:hidden">
                {(podcast.field_fichier && podcast.field_fichier.field_google_storage_link) && (
                  <div className='flex justify-between gap-2 items-center w-full'>
                      <span
                        className="text-[14px] text-foreground-light uppercase">{formatDate(podcast.created)}</span>

                    <Button asChild size="icon" iconLeft={<IconPlayerPlayFilled size='20'/>}>
                      <AudioPlayerButton media={{
                        'src': `${podcast.field_fichier.field_google_storage_link}`,
                        'metadatas': {
                          'illustration': `${absoluteUrl(podcast.field_media_illustration.field_media_image.uri.url)}`,
                          'title': `${podcast.field_emission?.title ? podcast.field_emission.title : ''}`,
                          'episode': `${podcast.title}`,
                          'presenter': `${podcast.field_animateurs[0]?.title}`,
                        }
                      }}>
                      </AudioPlayerButton>
                    </Button>
                  </div>
                )}

                {(index + 1) < podcasts.length && (<Separator className="mt-4" />)}

              </div>
            </article>
          )
        })}
      </div>

    </div>
  )
}
