import React from 'react';
import {cn, youtubeEmebedURL} from "@/lib/utils";
import {ParagraphYoutubeComponentProps} from "@/components/paragraphs/types";
import MediaYoutube from "@/components/media/MediaYoutube";

const ParagraphYoutube = ({node, className, ...props}: ParagraphYoutubeComponentProps) => {
  return (
    <>
      {node.field_video?.field_media_oembed_video && (
        <MediaYoutube url={node.field_video?.field_media_oembed_video} className={className} />
      )}
    </>
  );
};

export default ParagraphYoutube;
