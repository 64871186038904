import React from 'react';
import {cn, youtubeEmebedURL} from "@/lib/utils";

interface MediaYoutubeComponentProps extends React.HTMLAttributes<HTMLElement> {
  url: string
}

const MediaYoutube = ({url, className, ...props}: MediaYoutubeComponentProps) => {
  return (
    <>
      <iframe className={
        cn(
          "aspect-video",
          className
        )}
              width="100%"
              src={youtubeEmebedURL(url)}
              {...props}
      ></iframe>
    </>
  );
};

export default MediaYoutube;
