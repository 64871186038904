import { DrupalNode } from "next-drupal"
import Container from "@/components/ui/container";

interface NodeBasicPageProps {
  node: DrupalNode
}

export function NodeBasicPage({ node, ...props }: NodeBasicPageProps) {
  return (
      <Container>
          <article {...props}>
              <h1 className="mb-1 text-[40px] text-foreground-dark">{node.title}</h1>
              {node.body?.processed && (
                  <div
                      dangerouslySetInnerHTML={{__html: node.body?.processed}}
                      className="mt-6 font-serif text-xl leading-loose prose"
                  />
              )}
          </article>
      </Container>
  )
}
