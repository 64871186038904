import React from 'react';
import {ParagraphTextComponentProps} from "@/components/paragraphs/types";

const ParagraphText = ({node, className = '', ...props} : ParagraphTextComponentProps) => {
    return (
        <>
            {node.field_body?.processed && (
                <div className={className} dangerouslySetInnerHTML={{__html: node.field_body.processed}} {...props}></div>
            )}
        </>

    );
};

export default ParagraphText;
