import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {ReactElement} from "react";

const PodcastTeaserSkeleton = (): ReactElement => {

  return (
    <>
      <article className="w-full">
        <div className="flex gap-4 md:h-[140px] w-full">

          {/* Image */}
          <div className="min-w-[70px] min-h-[70px] md:min-w-[140px] md:max-w-[140px] flex-1">
            <figure className="mb-4 w-full overflow-hidden relative">
              <Skeleton className="w-full rounded-lg object-cover aspect-square "/>
            </figure>
          </div>

          {/* Titre et description */}
          <div className="flex flex-col w-full justify-between">
            <div>
              <Skeleton className="mb-3 mt-1 h-6 md:w-80 rounded"/>
              <Skeleton className="h-10 rounded"/>
            </div>

            {/* Date et bouton écouter PC */}
            <div className='gap-3 items-center hidden md:flex'>
              <Skeleton className="w-12 h-12 rounded"/>
              <Skeleton className="h-6 w-36 rounded"/>
            </div>
          </div>
        </div>

        {/* Date et bouton écouter Mobile */}
        <div className='mt-6 flex gap-3 justify-between items-center md:hidden'>
          <Skeleton className="h-6 w-36 rounded"/>
          <Skeleton className="w-12 h-12 rounded"/>
        </div>
      </article>

      <Separator className="my-4"/>
    </>

  )
}

export default PodcastTeaserSkeleton
