import React from 'react';
import {ParagraphPostFBComponentProps} from "@/components/paragraphs/types";
import {cn} from "@/lib/utils";

const ParagraphPostFB = ({node, className, ...props} : ParagraphPostFBComponentProps) => {
    return (
        <div className={
            cn(
                "",
                className
            )}
             {...props}
        >
            {node.field_url_iframe  && (
                <iframe className="mx-auto w-full sm:w-[500px] h-[500px]" src={node.field_url_iframe.uri} style={{border:'none', overflow:'hidden'}} allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            )}
        </div>
    );
};

export default ParagraphPostFB;