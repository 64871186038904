import Image from "next/image"
import Link from "next/link"
import {DrupalNode, DrupalParagraph} from "next-drupal"
import {ParagraphText} from "@/components/paragraphs";
import {absoluteUrl} from "@/lib/utils";

interface NodeArticlePromotedProps {
  article: DrupalNode
}

export function NodeArticlePromoted({ article, ...props }: NodeArticlePromotedProps) {

  const introduction = () => {
    const introParagraph = article.field_contenu_structure && article.field_contenu_structure.find((structure: DrupalParagraph) => structure.type === 'paragraph--texte')
    return (
      <div key={introParagraph.id} className="text-white text-xl line-clamp-3 lg:line-clamp-6"
        dangerouslySetInnerHTML={{__html: introParagraph.field_body?.processed ?? ''}}/>
    )
  }

  return (
    <article {...props}>
      <div className="md:aspect-[1152/440] relative">
        <div className="z-0 h-full flex flex-col justify-end bg-gradient-to-t from-black/60">
          <div className="md:w-1/2 p-8">
            <Link href={article.path.alias} className="stretched-link">
              <h4 className="text-3xl font-bold text-foreground-dark mb-2 text-white">{article.title}</h4>
            </Link>

            <div className="mb-3">
              {article.field_contenu_structure && introduction()}
            </div>
          </div>
        </div>

        {article.field_media_illustration && (
          <Link href={article.path.alias} className="absolute w-full top-0 bottom-0 -z-10">
            <figure className="mb-4 h-full">
              <Image
                src={absoluteUrl(article.field_media_illustration.field_media_image.uri.url)}
                width={article.field_media_illustration.field_media_image.resourceIdObjMeta.width}
                height={article.field_media_illustration.field_media_image.resourceIdObjMeta.height}
                alt={article.field_media_illustration.field_media_image.resourceIdObjMeta.alt}
                className="w-full h-full object-cover aspect-[9/11] md:aspect-[144/55]"
              />
            </figure>
          </Link>
        )}
      </div>
    </article>
  )
}
