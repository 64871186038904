import React from 'react';
import {useToast} from "@/components/ui/use-toast";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {Button} from "@/components/ui/button";
import {IconBrandFacebook, IconBrandX, IconLink, IconShare2} from "@tabler/icons-react";
import Link from "next/link";
import {DrupalNode} from "next-drupal";

const host = process.env.NEXT_PUBLIC_URL;

const SocialShare = ({node, txtButton = true, ...props}: { node: DrupalNode, txtButton?: boolean }) => {
  const currentUrl = host?.replace(/\/$/, '') + '/' + node.path.alias.replace(/^\//, '')
  const {toast} = useToast()

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(currentUrl)
      toast({description: "Le lien a été copié"})
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="w-full md:w-fit" variant='tertiary'
                iconLeft={<IconShare2 size='20'/>}>{txtButton ? 'Partager' : ''}</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Partager ce contenu</DropdownMenuLabel>
        <DropdownMenuItem asChild className="justify-start">
          <Link href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`} target="_blank"
                className="cursor-pointer">
            <IconBrandFacebook size={18} className="mr-2"/>
            Partager sur Facebook
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild className="justify-start">
          <Link href={`https://twitter.com/share?url=${currentUrl}`} target="_blank" className="cursor-pointer">
            <IconBrandX size={18} className="mr-2"/>
            Partager sur X
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild className="justify-start">
          <span onClick={copyToClipboard} className="cursor-pointer">
              <IconLink size={18} className="mr-2"/>
              Copier le lien
          </span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default SocialShare;
