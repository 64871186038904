import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination"
import type { UrlObject } from 'url';

const PaginationList = ({count, limit = 12, contextQuery, path = ''}: {count: number, limit: number, contextQuery: any, path?: string}) => {

  const page = contextQuery.page || 1;

  const currentPageNumber = Number(page);
  const limitNumber = Number(limit);
  const countNumber = Number(count);

  const totalPages = Math.ceil(countNumber / limitNumber);
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const addingQueryParams = (page: number): UrlObject => {
    return {
      pathname: path,
      query: {
        ...contextQuery,
        page: page,
      }
    }
  }

  return (
    <Pagination>
      <PaginationContent>

        {currentPageNumber > 1 &&
          <PaginationItem>
            <PaginationPrevious href={ addingQueryParams(currentPageNumber -1) as UrlObject & string } />
          </PaginationItem>
        }

        {totalPages > 1 && pages
          .filter(page => page >= currentPageNumber - 2 && page <= currentPageNumber + 2)
          .map((page, index) => {

            if(page >= currentPageNumber + 2 || page <= currentPageNumber - 2) {
              return <PaginationEllipsis key={`ellipsis-${page}`} />;
            } else {
              return (
                <PaginationItem key={`pagination-${page}`}>
                  <PaginationLink isActive={currentPageNumber == page} href={ addingQueryParams(page) as UrlObject & string }>{page}</PaginationLink>
                </PaginationItem>
              )
            }
          }
        )}

        {currentPageNumber < totalPages && (
          <PaginationItem>
            <PaginationNext href={ addingQueryParams(currentPageNumber + 1) as UrlObject & string } />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  )

}

export default PaginationList
