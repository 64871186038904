import React from 'react';
import {absoluteUrl, cn} from "@/lib/utils";
import {ParagraphFichierComponentProps} from "@/components/paragraphs/types";
import Link from "next/link";

const ParagraphFichier = ({node, className, ...props} : ParagraphFichierComponentProps) => {
    return (
        <>
            {(node.field_fichier_pdf && node.field_fichier_pdf.field_media_file) && (
              <Link
                href={absoluteUrl(node.field_fichier_pdf.field_media_file.uri.url)}
                className={
                    cn("underline",
                      className
                    )}
                {...props}
              >{node.field_fichier_pdf.name}</Link>
            )}
        </>
    );
};

export default ParagraphFichier;
