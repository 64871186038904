import {ReactElement, useEffect, useState} from "react";
import PaginationList from "@/components/pagination/PaginationList";
import {Separator} from "@/components/ui/separator";
import {Ads, adsExampleVariants} from "@/components/ui/ads";
import {NodePodcastTeaser} from "@/components/nodeTypes";
import {reloadRevive} from "@/lib/utils";

import {EpisodesSkeleton} from "@/components/emission"

const Episodes = ({episodes, count, limit, page, path, isLoading}): ReactElement => {


  useEffect(() => {
    reloadRevive();
  }, []);

  return (
    <>
      <h1 className="text-[24px]">Episodes</h1>

      <Separator className="my-2"/>

      {
        !isLoading &&
        episodes ? (
          episodes.map(
            (episode, index) => {

              // Insertion encart sponsor/pub -> disabled asking by Naomi
              // if (index === Math.ceil(episodes.length / 2) - 1) {
              //   return (
              //     <div key={'epi-' + index}>
              //
              //       <NodePodcastTeaser node={episode}/>
              //
              //       <div className='text-center justify-self-center'>
              //         <Ads adsId='53' dimension={'728090'} />
              //         <Separator className="my-2"/>
              //       </div>
              //
              //     </div>
              //
              //   );
              // }

              return (
                <div key={'epi-' + index}>
                  <NodePodcastTeaser node={episode}/>
                </div>
              );
            }
          )
        ) : (
          <>
            <EpisodesSkeleton/>
            <EpisodesSkeleton/>
            <EpisodesSkeleton/>
            <EpisodesSkeleton/>
            <EpisodesSkeleton/>
            <EpisodesSkeleton/>
            <EpisodesSkeleton/>
            <EpisodesSkeleton/>
            <EpisodesSkeleton/>
            <EpisodesSkeleton/>
            <EpisodesSkeleton/>
            <EpisodesSkeleton/>
          </>
        )
      }

      <PaginationList count={count} limit={limit} contextQuery={{page}} path={path}/>
    </>
  )
}

export default Episodes;
