import React from 'react';
import {IconPlayerPlayFilled} from "@tabler/icons-react";
import {AudioPlayerButton} from "@/components/audioPlayer";
import {Button} from "@/components/ui/button";
import {ParagraphPodcastComponentProps} from "@/components/paragraphs/types";
import {absoluteUrl} from "@/lib/utils";

const ParagraphPodcast = ({node, title = '', illustration = undefined} : ParagraphPodcastComponentProps) => {
    // @ts-ignore
    const audioSrc = (node.field_audio.field_media_audio_file.id === 'missing') ? node.field_audio.field_google_storage_link : absoluteUrl(node.field_audio.field_media_audio_file.uri.url);
    return (
        <>
            {node.field_audio?.field_media_audio_file && (
                <Button asChild iconLeft={<IconPlayerPlayFilled size='20'/>}>
                    <AudioPlayerButton media={{
                        'src': `${audioSrc}`,
                        'metadatas': {
                            'illustration': `${illustration}`,
                            'title': `${title}`,
                        }
                    }}>
                        Écouter
                    </AudioPlayerButton>
                </Button>
            )}
        </>
    );
};

export default ParagraphPodcast;
