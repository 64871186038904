import Image from "next/image"
import Link from "next/link"
import {DrupalNode, DrupalParagraph} from "next-drupal"
import {absoluteUrl, formatDatetime} from "lib/utils"
import Thematics from "@/components/ui/thematics";
import {HTMLProps} from "react";

interface NodeArticleTeaserProps extends HTMLProps<HTMLDivElement> {
  article: DrupalNode
}

export function NodeArticleTeaser({article, ...props}: NodeArticleTeaserProps) {

  const introduction = () => {
    const introParagraph = article.field_sous_titre ?? ''
    return (
      <div key={introParagraph.id} className="text-base line-clamp-6"
           dangerouslySetInnerHTML={{__html: introParagraph}}/>
    )
  }

  return (
    <article {...props}>
      {article.field_media_illustration && (
        <Link href={article.path.alias}>
          <figure className="mb-4 w-full overflow-hidden">
            <Image
              src={absoluteUrl(article.field_media_illustration.field_media_image.uri.url)}
              width={364}
              height={172}
              alt={article.field_media_illustration.field_media_image.resourceIdObjMeta.alt}
              className="w-full object-cover aspect-[363/172] group-hover:scale-110 duration-200"
            />
          </figure>
        </Link>
      )}

      <Link href={article.path.alias}>

        <h4 className="text-xl font-normal text-foreground-dark mb-1">{article.title}</h4>

        <div className="mb-3">
          {article.field_sous_titre && introduction()}
        </div>

        <div className="mb-4">
          <span className="mb-6 text-sm text-foreground-light uppercase">{formatDatetime(article.created)}</span>
        </div>

      </Link>


      {article.field_thematique && article.field_thematique.length > 0 && (
        <Thematics
          thematics={article.field_thematique.length ? article.field_thematique : [article.field_thematique]}/>
      )}
    </article>
  )
}
