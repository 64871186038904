import {DrupalNode} from "next-drupal"

import Container from "@/components/ui/container";
import {Ads, adsExampleVariants} from "@/components/ui/ads";
import * as React from "react";
import {NodePodcastTeaser} from "@/components/nodeTypes/node--podcast--teaser";
import {useEffect} from "react";
import {reloadRevive} from "@/lib/utils";

interface NodePodcastSearchProps {
  podcasts: DrupalNode[]
}

export function NodePodcastSearch({podcasts, ...props}: NodePodcastSearchProps) {

  useEffect(() => {
    reloadRevive();
  }, []);

  return (
    <Container className="py-10">

      <article {...props}>

        <div className="lg:grid grid-cols-2 grid-flow-col gap-6">

          <div className="col-span-11 lg:mb-0 mb-8">
            {podcasts.map((node, index) => {
              return (
                <div key={'epi-' + index}>
                  <NodePodcastTeaser node={node}/>
                </div>
              )
            })}
          </div>

          <aside className="col-span-1 text-center justify-self-center">
            <Ads adsId='54' dimension={'300600'} className='hidden mb-4 lg:block' />
            <Ads adsId='55' dimension={'300250'} />
          </aside>

        </div>

      </article>
    </Container>

  )
}
