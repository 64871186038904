import Image from "next/image"
import {DrupalNode} from "next-drupal"

import {absoluteUrl, formatDatetime} from "lib/utils"
import AudioPlayerButton from "@/components/audioPlayer/AudioPlayButton";
import {IconPlayerPlayFilled} from "@tabler/icons-react";
import {Button} from "@/components/ui/button";
import Link from "next/link";
import {Separator} from "@/components/ui/separator";

interface NodePodcastProps {
  node: DrupalNode,
}

export function NodePodcastTeaser({node, ...props}: NodePodcastProps) {

  const ressourceImg = node.field_media_illustration ? node.field_media_illustration.field_media_image : (node.field_image_archive ? node.field_image_archive : false)
  const hasGoogleCloudUrl = node.field_fichier &&
      node.field_fichier.field_google_storage_link !== null &&
      node.field_fichier.field_google_storage_link !== ''
  const ressourceAudio = node.field_fichier ?
      (hasGoogleCloudUrl ? node.field_fichier.field_google_storage_link : absoluteUrl(node.field_fichier.field_google_storage_link)) :
      (node.field_mp3_url ? node.field_mp3_url.uri : false)
  return (
    <>
      <article {...props}>
        <div className="flex gap-4 md:h-[140px] w-full">
          {ressourceImg && (
            <Link href={node.path.alias} className="min-w-[70px] min-h-[70px] md:min-w-[140px] md:max-w-[140px] flex-1">
              <figure className="mb-4 w-full overflow-hidden relative">
                <Image
                  src={absoluteUrl(ressourceImg.uri.url)}
                  width={140}
                  height={140}
                  alt={ressourceImg.resourceIdObjMeta.alt}
                  className="w-full rounded-lg object-cover aspect-square group-hover:scale-110 duration-200"
                />

                {node.field_fichier_video?.field_media_oembed_video && (
                  <figcaption
                    className="bg-neutral-800 text-white px-1 text-xs text-center text-gray-600 absolute top-1 left-1 rounded-sm opacity-80">
                    VIDEO
                  </figcaption>
                )}
              </figure>
            </Link>
          )}

          <div className="flex flex-col w-full justify-between">
            <Link href={node.path.alias}>
              <h4 className="mb-1 text-[20px] line-clamp-2 text-foreground-dark">{node.title}</h4>
              <p className="text-base line-clamp-2" dangerouslySetInnerHTML={{__html: node.body?.summary ?? ''}}/>
            </Link>

            <div className='gap-3 items-center hidden md:flex'>
              {ressourceAudio && (
                <Button size="icon" asChild iconLeft={<IconPlayerPlayFilled size='20'/>}>
                  <AudioPlayerButton media={{
                    'src': `${ressourceAudio}`,
                    'metadatas': {
                      'illustration': ressourceImg ? absoluteUrl(ressourceImg.uri.url) : undefined,
                      'title': `${node.field_emission.title ? node.field_emission.title : ''}`,
                      'episode': `${node.title}`,
                      'presenter': `${node.field_animateurs ? node.field_animateurs[0].title : ''}`,
                    }
                  }}>
                  </AudioPlayerButton>
                </Button>
              )}

              <span
                className="text-[14px] text-sm text-foreground-light uppercase">{formatDatetime(node.created)}</span>
            </div>

          </div>
        </div>

        <div className='mt-6 flex gap-3 justify-between items-center md:hidden'>
          <span
            className="text-[14px] text-sm text-foreground-light uppercase">{formatDatetime(node.created)}</span>

          {ressourceAudio && (
            <Button size="icon" asChild iconLeft={<IconPlayerPlayFilled size='20'/>}>
              <AudioPlayerButton media={{
                'src': `${ressourceAudio}`,
                'metadatas': {
                  'illustration': ressourceImg ? absoluteUrl(ressourceImg.uri.url) : undefined,
                  'title': `${node.field_emission.title ? node.field_emission.title : ''}`,
                  'episode': `${node.title}`,
                  'presenter': `${node.field_animateurs ? node.field_animateurs[0].title : ''}`,
                }
              }}>
              </AudioPlayerButton>
            </Button>
          )}
        </div>
      </article>

      <Separator className="my-4"/>
    </>

  )
}
