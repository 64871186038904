import Image from "next/image"
import {DrupalNode} from "next-drupal"

import {absoluteUrl, weekDayByNumber} from "lib/utils"
import {IconClockHour10} from "@tabler/icons-react";
import Link from "next/link";

interface NodeEmissionProps {
  node: DrupalNode[]
}

export function NodePodcastEmission({node, ...props}: NodeEmissionProps) {
  return (
    <>
      <div {...props}>

        <div className="grid md:grid-cols-4 sm:grid-cols-2 gap-4">

          {node.map((emission, index) => {

            return (

              <div key={index}>

                <Link href={emission.path.alias} >
                  {emission.field_media_illustration && (
                    <figure className="mb-2">
                      <Image
                        src={absoluteUrl(emission.field_media_illustration.field_media_image.uri.url)}
                        width={emission.field_media_illustration.field_media_image.resourceIdObjMeta.width}
                        height={emission.field_media_illustration.field_media_image.resourceIdObjMeta.height}
                        alt={emission.field_media_illustration.field_media_image.resourceIdObjMeta.alt ? emission.field_media_illustration.field_media_image.resourceIdObjMeta.alt : ''}
                        priority
                        className="object-cover w-full"
                      />
                    </figure>
                  )}

                  <div>
                    <div className="xl:grid grid-flow-col gap-4">
                      <div>
                        <h1 className="mb-1 text-[24px] text-foreground-dark">{emission.title}</h1>

                        {emission.field_animateurs && emission.field_animateurs.length > 0 && (
                          <div
                            className="mb-4 flex gap-1 items-center flex-wrap xl:justify-start">
                            {emission.field_animateurs.map((animateur, i) => (
                              <span key={animateur.id} className="text-[14px]">{animateur.title}</span>
                            ))}
                          </div>
                        )}

                        {emission.field_planifications && emission.field_planifications.length > 0 && (
                          <div
                            className="uppercase text-neutral-700 flex gap-1 items-center flex-wrap justify-center xl:justify-start mb-4">
                            <IconClockHour10/>
                            <span>Diffusion&nbsp;:</span>
                            {" "}
                            {emission.field_planifications.map((planification, index) => (
                              <span key={planification.id}>
                                                    {weekDayByNumber(planification.field_jour)}&nbsp;:&nbsp;{planification.field_heure_debut.field_heure.toString().padStart(2, 0)}H{planification.field_heure_debut.field_minute.toString().padStart(2, 0)}&nbsp;-&nbsp;{planification.field_heure_fin.field_heure.toString().padStart(2, 0)}H{planification.field_heure_fin.field_minute.toString().padStart(2, 0)}{" "}
                                                </span>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

            )

          })}

        </div>

      </div>
    </>
  )
}
