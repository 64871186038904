import Image from "next/image"
import Link from "next/link"
import {DrupalNode, DrupalParagraph} from "next-drupal"
import {absoluteUrl, cn, formatDatetime} from "lib/utils"
import Thematics from "@/components/ui/thematics";
import {HTMLProps} from "react";
import {IconMicrophone, IconNews} from "@tabler/icons-react";
import {cva, VariantProps} from "class-variance-authority";

const useVariantStyles = cva(
  '',
  {
    variants: {
      heading: {
        base: 'text-foreground-dark',
        light: 'text-white'
      },
      shortText: {
        base: '',
        light: 'text-white'
      }
    },
    defaultVariants: {
      heading: 'base',
      shortText: 'base',
    }
  }
)

interface NodeDefaultTeaserProps extends HTMLProps<HTMLDivElement>, VariantProps<typeof useVariantStyles> {
  node: DrupalNode
  showType?: boolean
}

export function NodeDefaultTeaser({node, showType = false, heading = 'base', shortText = 'base', ...props}: NodeDefaultTeaserProps) {
  const introduction = (className) => {
    let introText = ''
    if (node.type === 'node--podcast') {
      introText = node.body?.summary ?? ''
    } else if (node.type === 'node--article') {
      introText = node.field_sous_titre ?? ''
    }

    return (
      <div key={"intro" + node.id} className={`${className} text-base line-clamp-6`}
           dangerouslySetInnerHTML={{__html: introText}}/>
    )
  }

  const ressourceImg = node.field_media_illustration ? node.field_media_illustration.field_media_image : (node.field_image_archive ? node.field_image_archive : false)
  const imgSrc = (node.field_media_illustration && ressourceImg.image_style_uri?.rrb_large) ? ressourceImg.image_style_uri.rrb_large : ressourceImg ? absoluteUrl(ressourceImg.uri.url) : false

  return (
    <article {...props}>
      <div className="group relative">
        {ressourceImg && (
          <div className="relative">
            <Link href={node.path.alias}>
              <figure className="mb-4 w-full overflow-hidden">
                <Image
                  src={imgSrc}
                  width={364}
                  height={172}
                  alt={ressourceImg.resourceIdObjMeta.alt}
                  className="w-full object-cover aspect-[91/43] group-hover:scale-110 duration-200"
                />
              </figure>

              {showType && (
                <div
                  className="text-sm absolute bottom-4 left-4 w-fit flex gap-1 items-center px-2 py-1 bg-black/50 text-white uppercase rounded backdrop-blur-sm">
                  {node.type === 'node--podcast' && (<><IconMicrophone/> podcast</>)}
                  {node.type === 'node--article' && (<><IconNews/> article</>)}
                </div>
              )}
            </Link>
          </div>
        )}

        <Link href={node.path.alias}>
          <h4 className={cn(useVariantStyles({heading}), 'text-xl font-normal mb-1')}>{node.title}</h4>

          <div className="mb-3">
            {introduction(cn(useVariantStyles({shortText})))}
          </div>

          <div className="mb-4">
            <span className="mb-6 text-sm text-foreground-light uppercase">{formatDatetime(node.created)}</span>
          </div>
        </Link>

        {node.field_thematique && node.field_thematique.length > 0 && (
          <Thematics
            thematics={node.field_thematique.length ? node.field_thematique : [node.field_thematique]}
            variant={heading}/>
        )}
      </div>
    </article>
  )
}
