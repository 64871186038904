import {ReactElement} from "react";
import {cva, VariantProps} from "class-variance-authority";
import {cn} from "@/lib/utils";

const useVariantStyles = cva(
  '',
  {
    variants: {
      variant: {
        base: 'bg-neutral-100',
        light: 'text-neutral-100 bg-neutral-800'
      }
    },
    defaultVariants: {
      variant: 'base',
    }
  }
)

interface BadgeProps extends VariantProps<typeof useVariantStyles>{
  text?: string
  className?: string
}

const Badge = ({text = '', className = '', variant = 'base'} : BadgeProps) : ReactElement => {
  return <span className={cn(useVariantStyles({variant}), "p-2 uppercase text-[10px]", className)}>{text}</span>
}

export default Badge
