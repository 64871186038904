import {ReactElement} from "react";
import Badge from "@/components/ui/badge";
import {cva, VariantProps} from "class-variance-authority";
import Link from "next/link";

const useVariantStyles = cva(
  '',
  {
    variants: {
      variant: {
        base: 'bg-neutral-100',
        light: 'text-neutral-100 bg-neutral-800'
      }
    },
    defaultVariants: {
      variant: 'base',
    }
  }
)

interface ThematicsProps extends VariantProps<typeof useVariantStyles>{
  thematics: ThematicProps[]
}

interface ThematicProps {
  name: string
  resourceIdObjMeta: {drupal_internal__target_id: number}
}

const Thematics = ({thematics, variant} : ThematicsProps) : ReactElement => {
  return (
    <div className="flex flex-wrap gap-2 mb-4">
      {
        thematics && thematics.map((thematic: ThematicProps, index: number) => (
          <Link key={thematic.resourceIdObjMeta.drupal_internal__target_id} href={`/articles?thematique=${thematic.resourceIdObjMeta.drupal_internal__target_id}`}>
            <Badge key={index} text={`#${thematic.name}`} variant={variant}/>
          </Link>
        ))
      }
    </div>
  )
}

export default Thematics
