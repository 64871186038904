import { cn } from "@/lib/utils"
import {cva, VariantProps} from "class-variance-authority";

const skeletonVariants = cva(
  "animate-pulse",
  {
    variants: {
      variant: {
        default: "bg-muted",
        contrast: "bg-neutral-600",
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
)

export interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof skeletonVariants> {}

function Skeleton({
  className,
  variant,
  ...props
}: SkeletonProps) {
  return (
    <div
      className={cn(skeletonVariants({variant}), className)}
      {...props}
    />
  )
}

export { Skeleton }
