import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList, BreadcrumbPage,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb";
import Link from "next/link";
import {BreadcrumbProps} from './type'

const Breadcrumbs = ({entries}: BreadcrumbProps) => {

  const isLast = (index: number): boolean => {
    return index >= entries.length - 1
  }

  const Renderer = ({entry, index}) => {
    return (
      <>
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            {
              entry.href ?
                <Link href={entry.href}>{entry.label}</Link>
                :
                <BreadcrumbPage>{entry.label}</BreadcrumbPage>
            }
          </BreadcrumbLink>
        </BreadcrumbItem>
        {!isLast(index) && <BreadcrumbSeparator/>}
      </>
    )
  }

  return (
    <Breadcrumb className='mb-3'>
      <BreadcrumbList>

        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link href='/'>Accueil</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator/>
        {
          entries.map((entry, index) => (
            <Renderer index={index} entry={entry} key={index} />
          ))
        }
      </BreadcrumbList>
    </Breadcrumb>
  )
}

export default Breadcrumbs
