import Image from "next/image"
import {DrupalNode} from "next-drupal"

import {absoluteUrl, formatDatetime} from "lib/utils"

interface NodeArticleProps {
    node: DrupalNode
}

export function NodeAnnonceLegaleTeaser({node, ...props}: NodeArticleProps) {
    return (
        <article {...props} className='flex md:flex-row flex-col bg-neutral-50 p-4 rounded-lg flex gap-4 annonces-legales-teaser '>
            {node.field_image && (
                <figure className="md:w-[172px] md:h-[172px] w-full h-28 flex-none">
                    <Image
                        src={absoluteUrl(node.field_image.uri.url)}
                        width={172}
                        height={172}
                        alt={node.field_image.resourceIdObjMeta.alt}
                        className="rounded-lg md:w-[172px] md:h-[172px] w-full h-28 object-cover object-center"
                    />
                </figure>
            )}
            <div className="flex-auto">
                <div>
                    <span className="mb-6 text-sm text-foreground-light uppercase">{formatDatetime(node.created)}</span>
                </div>
                <h2 className="mb-2 text-xl text-foreground-dark">{node.title}</h2>
                {node.body?.processed && (
                    <div className="text-sm" dangerouslySetInnerHTML={{__html: node.body?.processed}}
                    />
                )}
            </div>
        </article>
    )
}
