import Image from "next/image"
import {DrupalNode} from "next-drupal"

import {absoluteUrl, formatDatetime, reloadRevive} from "lib/utils"
import AudioPlayerButton from "@/components/audioPlayer/AudioPlayButton";
import {IconDownload, IconPlayerPlayFilled} from "@tabler/icons-react";
import {Button} from "@/components/ui/button";
import Container from "@/components/ui/container";
import Link from "next/link";
import {SocialShare} from "@/components/socialShare";
import {Separator} from "@/components/ui/separator";
import {Ads} from "@/components/ui/ads";
import Thematics from "@/components/ui/thematics";
import MediaYoutube from "@/components/media/MediaYoutube";
import * as React from "react";
import Breadcrumbs from "@/components/breadcrumb";
import {useEffect} from "react";

interface NodePodcastProps {
  node: DrupalNode
}

export function NodePodcast({node, ...props}: NodePodcastProps) {
  const breadcrumb = [
    {label: 'Podcasts', href: '/podcasts'},
    {label: node.title}
  ];

  const ressourceImg = node.field_media_illustration ? node.field_media_illustration.field_media_image : (node.field_image_archive ? node.field_image_archive : false)
  const hasGoogleCloudUrl = node.field_fichier &&
    node.field_fichier.field_google_storage_link !== null &&
    node.field_fichier.field_google_storage_link !== ''
  const ressourceAudio = node.field_fichier ?
    (hasGoogleCloudUrl ? node.field_fichier.field_google_storage_link : absoluteUrl(node.field_fichier.field_google_storage_link)) :
    (node.field_mp3_url ? node.field_mp3_url.uri : false)

  useEffect(() => {
    reloadRevive();
  }, []);

  return (
    <Container className="py-10">
      <Breadcrumbs entries={breadcrumb}/>

      <article {...props}>

        <div className="lg:grid grid-flow-col gap-6">
          <div className="col-span-2 lg:mb-0 mb-8">

            <div className="xl:grid grid-flow-col gap-5 text-center md:text-left">

              {ressourceImg && (
                <figure className="col-span-1 inline-block">
                  <Image className='rounded-lg'
                         src={absoluteUrl(ressourceImg.uri.url)}
                         width={ressourceImg.resourceIdObjMeta.width}
                         height={ressourceImg.resourceIdObjMeta.height}
                         alt={ressourceImg.resourceIdObjMeta.alt ? ressourceImg.resourceIdObjMeta.alt : ''}
                         priority
                  />
                  {ressourceImg.resourceIdObjMeta.title && (
                    <figcaption className="py-2 text-sm text-center text-gray-600">
                      {ressourceImg.resourceIdObjMeta.title}
                    </figcaption>
                  )}
                </figure>
              )}

              <div className="col-span">
                <div className="xl:grid grid-flow-col">
                  <div>
                    {node.field_emission.title && (
                      <p className="text-lg leading-snug max-md:mt-6">{node.field_emission.title}</p>

                    )}

                    <h1 className="mb-1 text-[32px] text-foreground-dark">{node.title}</h1>

                    {node.field_animateurs && node.field_animateurs.length > 0 && (
                      <div
                        className="mb-4 flex flex-wrap justify-center md:justify-start text-foreground-light">
                        {node.field_animateurs.map((animateur, i) => (
                          <span key={animateur.id}>{animateur.title}</span>
                        ))}
                      </div>
                    )}

                    <span className="text-foreground-light text-sm uppercase">{formatDatetime(node.created)}</span>

                    <div className='flex gap-2 my-6'>

                      {ressourceAudio && (
                        <>
                          <Button asChild iconLeft={<IconPlayerPlayFilled size='20'/>} className="basis-full">
                            <AudioPlayerButton media={{
                              'src': `${ressourceAudio}`,
                              'metadatas': {
                                'illustration': ressourceImg ? absoluteUrl(ressourceImg.uri.url) : undefined,
                                'title': `${node.field_emission.title ? node.field_emission.title : ''}`,
                                'episode': `${node.title}`,
                                'presenter': `${node.field_animateurs ? node.field_animateurs[0].title :  ''}`,
                              }
                            }}>
                              Écouter
                            </AudioPlayerButton>
                          </Button>

                          <Button asChild variant='secondary' iconRight={<IconDownload size='20'/>} className="hidden md:flex">
                            <Link href={ressourceAudio} target="_blank"
                                  rel="noopener noreferrer" download>Télécharger</Link>
                          </Button>
                        </>
                      )}

                      <div className="basis-0">
                        <SocialShare node={node} txtButton={false}/>
                      </div>
                    </div>

                    {node.body?.summary && (
                      <div>
                        <span>{node.body?.summary}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Separator className='mb-6 mt-12 md:mt-4'/>

            {node.body?.processed && (
              <div
                dangerouslySetInnerHTML={{__html: node.body?.processed}}
                className="mt-6"
              />
            )}

            {node.field_fichier_video?.field_media_oembed_video && (
              <MediaYoutube url={node.field_fichier_video?.field_media_oembed_video} className="mt-10"/>
            )}

            {node.field_thematique && node.field_thematique.length > 0 && (
              <div className="mt-10">
                <Thematics
                  thematics={node.field_thematique.length ? node.field_thematique : [node.field_thematique]}/>
              </div>
            )}

          </div>

          <aside>
            <div className="sticky top-28 col-span-1 text-center">
              <Ads adsId='54' dimension={'300600'} className='hidden mb-4 lg:block' />
              <Ads adsId='55' dimension={'300250'} />
            </div>
          </aside>

        </div>

      </article>
    </Container>
  )
}
