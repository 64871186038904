import React from 'react';
import Image from "next/image";
import {absoluteUrl, cn} from "@/lib/utils";
import {ParagraphImageComponentProps} from "@/components/paragraphs/types";

const ParagraphImage = ({node, className, ...props} : ParagraphImageComponentProps) => {
    return (
        <>
            {(node.field_image && node.field_image.field_media_image)&& (
                <figure className={
                    cn("bg-secondary text-neutral-900",
                        className
                    )}
                        {...props}
                >
                    <Image
                        className="w-full object-cover"
                        src={absoluteUrl(node.field_image.field_media_image.uri.url)}
                        width={node.field_image.field_media_image.resourceIdObjMeta?.width}
                        height={node.field_image.field_media_image.resourceIdObjMeta?.height}
                        alt={node.field_image.field_media_image.resourceIdObjMeta?.alt ? node.field_image.field_media_image.resourceIdObjMeta.alt : ''}
                        priority
                    />
                    {node.field_legende && (
                        <figcaption className="p-3">
                            {node.field_legende}
                        </figcaption>
                    )}
                </figure>
            )}
        </>
    );
};

export default ParagraphImage;
