import {DrupalNode} from "next-drupal";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb";
import Link from "next/link";
import {Separator} from "@/components/ui/separator";
import {formatDatetime} from "@/lib/utils";
import {SocialShare} from "@/components/socialShare";
import Container from "@/components/ui/container";
import Breadcrumbs from "@/components/breadcrumb";

interface NodeBasicPageProps {
  node: DrupalNode,
}

export function NodeAvis({ node, ...props }: NodeBasicPageProps) {
  const breadcrumb = [
    {label: 'Avis de décès et de messe', href: '/avis' },
    {label: node.title}
  ];

  return (
      <Container className="py-10">
        <article {...props}>
          <div className="md:grid md:grid-cols-6 md:gap-6">
            <div className="col-start-1 col-end-6">
              <Breadcrumbs entries={breadcrumb} />

              <h1 className="mb-1 text-[40px] text-foreground-dark">{node.title}</h1>
              <Separator className='hidden md:flex mb-4'/>
            </div>
            <div className="col-start-6 relative justify-self-end">
              <SocialShare node={node} />
            </div>
          </div>

          <Separator className="my-4 md:hidden"/>

          <div className="mb-4 text-sm text-foreground-light uppercase">
            <span>{formatDatetime(node.created)}</span>
          </div>

          <div className="md:grid md:grid-cols-4">
            <div
                dangerouslySetInnerHTML={{__html: node.body?.processed}}
                className="col-start-2 col-end-4">
            </div>
          </div>
        </article>
      </Container>
  )
}
