import {DrupalNode} from "next-drupal"

import {formatDatetime} from "lib/utils"
import Link from "next/link";

interface NodeAvisProps {
    node: DrupalNode
}

export function NodeAvisTeaser({node, ...props}: NodeAvisProps) {
    return (
        <Link href={node.path.alias}>
            <article {...props} className='flex flex-col bg-neutral-50 p-4 gap-4 rounded-lg'>
                <div>
                    <span className="uppercase text-[10px]">Avis de {node.field_type === 'deces' ? 'décès' : 'messe'}</span>
                    <h2 className="mb-2 text-xl text-foreground-dark">{node.title}</h2>
                </div>

                {node.body?.processed && (
                    <div className="line-clamp-4" dangerouslySetInnerHTML={{__html: node.body?.processed}}
                    />
                )}

                <div>
                    <span className="mb-6 text-sm text-foreground-light uppercase">{formatDatetime(node.created)}</span>
                </div>
            </article>
        </Link>
    )
}
